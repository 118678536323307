import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./css/reset.css";

import Data from "./Data";
import Home from "./pages/Home";
import Venue from "./pages/Venue";
import NotFound from "./pages/NotFound";

const App = () => (
  <Data
    apis={{
      gameplay: String(process.env.REACT_APP_GAMEPLAY_API),
      venues: String(process.env.REACT_APP_VENUES_API),
    }}
  >
    <Router>
      <Switch>
        <Route path="/:shortName">
          <Venue />
        </Route>
        <Route path="/">
          <Home />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </Router>
  </Data>
);

export default App;
