import React, { createContext, useContext } from "react";
import axios from "axios";
import { v4 as uuid } from "uuid";

interface AnalyticsEventData {
  [key: string]: string | number | boolean | null;
}

interface AnalyticsContext {
  trackEvent: (type: string, data?: AnalyticsEventData) => void;
}

const Context = createContext<any>({});

export const useAnalytics = (): AnalyticsContext =>
  useContext<AnalyticsContext>(Context);

interface AnalyticsProps {
  children: React.ReactNode;
  venueName: string;
}

const Analytics = ({ venueName, children }: AnalyticsProps) => {
  const context: AnalyticsContext = {
    trackEvent: (type: string, data: AnalyticsEventData = {}) => {
      axios.post(String(process.env.REACT_APP_EVENTS_API), {
        specversion: "1.0",
        id: uuid(),
        source: String(process.env.REACT_APP_URL),
        type: `${process.env.REACT_APP_DNS_NAME}${type}`,
        time: new Date().toISOString(),
        data: {
          venue: venueName,
          ...data,
        },
      });
    },
  };

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Analytics;
