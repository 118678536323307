import React from "react";
import styled from "@emotion/styled";

import Logo from "../../components/Logo/Logo";
import VenueLogo from "../../components/Logo/VenueLogo";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  padding: 0.8rem 1rem;
`;

interface Props {
  venueLogo: string;
}

const LogoBar = ({ venueLogo }: Props) => (
  <Wrapper>
    <VenueLogo src={venueLogo} alt="Venue Logo" />
    <Logo />
  </Wrapper>
);

export default LogoBar;
