import styled from "@emotion/styled";

const Paragraph = styled.p`
  margin: 2.4rem auto 0.6rem;
  font-family: "Barlow", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.4;
  text-align: center;

  @media print {
    font-size: 1rem;
    margin: 0.6rem auto 0;
  }
`;

export const Small = styled.small`
  font-size: 0.8rem;
`;

export default Paragraph;
