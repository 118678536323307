import styled from "@emotion/styled";

const Display = styled.div`
  font-family: "Barlow Semi Condensed", sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
`;

export default Display;
