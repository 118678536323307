import React from "react";

import Page from "../components/Page/Page";
import Logo from "../components/Logo/Logo";

const Home = () => (
  <Page>
    <Logo display3d background="#000" width="272" height="136" />
  </Page>
);

export default Home;
