import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

/*
 * Data Storage Context
 *
 */

interface DataContext {
  apis: { [key: string]: string };
}

const Context = createContext<DataContext>({ apis: { default: "" } });

interface DataConfig {
  apis: { [key: string]: string };
  children: React.ReactNode;
}

const Data = ({ apis, children }: DataConfig) => {
  const context: DataContext = { apis };
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export default Data;

/*
 * Data Storage Hooks
 *
 */

interface DataResult<T = any> {
  data: T | null;
  error: string | false;
  loading: boolean;
}

interface LazyDataResult<T = any> {
  data: T | null;
  error: string | false;
}

export function usePost<T = any>(
  path: string,
  requestData: any = {},
  api: string = "default"
): DataResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | false>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { apis } = useContext<DataContext>(Context);

  if (typeof apis[api] === "undefined") {
    setError(`API url for "${api}" not found!`);
    setLoading(false);
  }

  const host = apis[api];
  const uri = `${host}${path}`;

  useEffect(() => {
    axios
      .post<T>(uri, requestData, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(async ({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(String(error));
        setLoading(false);
      });
  }, [uri, requestData]);

  return { data, error, loading };
}

export function useLazyPost<T = any>(
  path: string,
  api: string = "default"
): (requestData?: any) => Promise<LazyDataResult<T>> {
  const { apis } = useContext<DataContext>(Context);

  async function makeRequest(
    requestData: any = {}
  ): Promise<LazyDataResult<T>> {
    const result: LazyDataResult<T> = {
      data: null,
      error: false,
    };

    if (typeof apis[api] === "undefined") {
      result.error = `API url for "${api}" not found!`;
      return result;
    }

    const host = apis[api];
    const uri = `${host}${path}`;

    try {
      const response = await axios.post<T>(uri, requestData, {
        headers: {
          "content-type": "application/json",
        },
      });
      result.data = response.data;
    } catch (error) {
      result.error = String(error);
    }

    return result;
  }

  return makeRequest;
}

export function useGet<T = any>(
  path: string,
  api: string = "default"
): DataResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | false>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const { apis } = useContext<DataContext>(Context);

  if (typeof apis[api] === "undefined") {
    setError(`API url for "${api}" not found!`);
    setLoading(false);
  }

  const host = apis[api];
  const uri = `${host}${path}`;

  useEffect(() => {
    axios
      .get<T>(uri)
      .then(async ({ data }) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(String(error));
        setLoading(false);
      });
  }, [uri]);

  return { data, error, loading };
}

export function useLazyGet<T = any>(
  path: string,
  api: string = "default"
): () => Promise<LazyDataResult<T>> {
  const { apis } = useContext<DataContext>(Context);

  async function makeRequest(): Promise<LazyDataResult<T>> {
    const result: LazyDataResult<T> = {
      data: null,
      error: false,
    };

    if (typeof apis[api] === "undefined") {
      result.error = `API url for "${api}" not found!`;
      return result;
    }

    const host = apis[api];
    const uri = `${host}${path}`;

    try {
      const response = await axios.get<T>(uri);
      result.data = response.data;
    } catch (error) {
      result.error = String(error);
    }

    return result;
  }

  return makeRequest;
}
