import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import LogoBar from "../Logo/LogoBar";
import BackButton from "../Inputs/BackButton";
import HomeButton from "../Inputs/HomeButton";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 100%;
  background-color: #2c3d56;
  color: #fff;

  @media print {
    display: none;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  width: 100%;
`;

interface Props {
  className?: string;
  children?: ReactNode;
  venueLogo?: string | null;
  onBack?: () => void;
  onHome?: () => void;
}

const Page = ({ className, children, venueLogo, onBack, onHome }: Props) => (
  <Wrapper>
    {venueLogo && <LogoBar venueLogo={venueLogo} />}
    <Content className={className}>{children}</Content>
    <Footer>
      {onBack && <BackButton onClick={onBack} />}
      {onHome && <HomeButton onClick={onHome} />}
    </Footer>
  </Wrapper>
);

export default Page;
