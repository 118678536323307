import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import throttle from "lodash.throttle";

import { useGet } from "../Data";
import Analytics from "../Analytics";
import Employee from "./Employee";
import Start from "./Start";
import Redeem from "./Redeem";
import Loading from "./Loading";
import NotFound from "./NotFound";

const INACTIVITY_PERIOD = 180000;

interface Props {
  id: string;
  name: string;
  shortName: string;
  prefix: string;
  logo: string | null;
}

const Switch = ({ id, prefix, logo }: Props) => {
  const [employee, setEmployee] = useState<Employee>();
  const [action, setAction] = useState<Action>();

  useEffect(() => {
    const resetApp = () => {
      // return to home after 5 minutes of inactivity
      setEmployee(undefined);
      setAction(undefined);
    };

    let timeout = setTimeout(resetApp, INACTIVITY_PERIOD);

    const onInteraction = throttle(() => {
      clearTimeout(timeout);
      timeout = setTimeout(resetApp, INACTIVITY_PERIOD);
    }, 1000);

    document.body.addEventListener("mousemove", onInteraction);
    document.body.addEventListener("scroll", onInteraction);
    document.body.addEventListener("keydown", onInteraction);
    document.body.addEventListener("click", onInteraction);
    document.body.addEventListener("touchstart", onInteraction);

    return () => {
      clearTimeout(timeout);
    };
  });

  if (action === "redeem") {
    return (
      <Redeem
        venueId={id}
        venuePrefix={prefix}
        venueLogo={logo}
        onBack={() => setAction(undefined)}
        onHome={() => {
          setAction(undefined);
          setEmployee(undefined);
        }}
      />
    );
  }

  if (employee) {
    return (
      <Start
        employeeName={employee.name}
        venueLogo={logo}
        onSetAction={(a) => setAction(a)}
        onBack={() => setEmployee(undefined)}
      />
    );
  }

  return (
    <Employee venueId={id} venueLogo={logo} onSetUser={(e) => setEmployee(e)} />
  );
};

const Wrapper = () => {
  const { shortName } = useParams<{ shortName: string }>();

  const { data, loading, error } = useGet<Venue>(
    `/public/tavern/venues/${shortName}`,
    "venues"
  );

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <NotFound />;
  }

  if (!data) {
    return null;
  }

  return (
    <Analytics venueName={data.name}>
      <Switch
        id={data.id}
        name={data.name}
        shortName={data.shortName}
        prefix={data.prefix}
        logo={data.logo}
      />
    </Analytics>
  );
};

export default Wrapper;
