import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";

import Page from "../components/Page/Page";
import Logo from "../components/Logo/Logo";
import Display from "../components/Text/Display";

const Text = styled(Display)`
  margin-top: 3rem;
`;

const NotFound = () => {
  const [display, setDisplay] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplay(true);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <Page>
      {display && (
        <>
          <Logo display3d background="#000" width="272" height="136" />
          <Text>Not Found!</Text>
        </>
      )}
    </Page>
  );
};

export default NotFound;
