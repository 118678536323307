import styled from "@emotion/styled";

interface Props {
  highlight?: boolean;
}

const Button = styled.button<Props>`
  width: 220px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${({ highlight }) => (highlight ? "#ffffff" : "#ddaa41")};
  border: 4px solid ${({ highlight }) => (highlight ? "#ddaa41" : "#ffffff")};
  border-radius: 8px;
  outline: 0;
  font-family: Fira Mono;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  color: #000000;

  &:disabled {
    opacity: 0.5;
  }

  &:active {
    background-color: #ffffff;
    border: 4px solid #ddaa41;
  }
`;

export const SmallButton = styled(Button)`
  width: 80px;
  height: 80px;
  font-size: 2rem;
`;

export const EmployeeButton = styled(Button)`
  width: 160px;
  height: 80px;
  font-size: 1.2rem;
  word-spacing: 160px;
`;

export default Button;
