import styled from "@emotion/styled";

const Button = styled.button`
  padding: 1.4rem;
  align-self: flex-start;
  font-family: "Barlow", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  background: transparent;
  border: 0;
  outline: 0;

  &::before {
    margin-right: 0.4rem;
    content: "\\2190";
  }
`;

interface Props {
  onClick?: () => void;
}

const BackButton = ({ onClick }: Props) => {
  const handleClick = onClick || (() => {});
  return <Button onClick={handleClick}>Back</Button>;
};

export default BackButton;
