import styled from "@emotion/styled";

interface Props {
  background?: string;
}

const Input = styled.input<Props>`
  width: 300px;
  padding: 1rem;
  border: 0;
  border-radius: 8px;
  outline: 0;
  font-family: Fira Mono;
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;
  background: ${({ background }) => background || "#fff"};
  color: ${({ background }) =>
    !background || background === "#fff" ? "#000" : "#fff"};

  &::placeholder {
    color: #ccc;
  }

  &:focus::placeholder {
    color: ${({ background }) => background || "#fff"};
  }
`;

export default Input;
