import React, { useState } from "react";
import throttle from "lodash.throttle";

import { useLazyPost } from "../Data";
import Page from "../components/Page/Page";
import Button from "../components/Inputs/Button";
import Display from "../components/Text/Display";
import Paragraph from "../components/Text/Paragraph";
import CodeInput from "../components/Inputs/CodeInput";

const GREEN = "#6AA6A0";
const PINK = "#B56D8D";
const ORANGE = "#D17548";
const WHITE = "#fff";

interface Props {
  venueId: string;
  venuePrefix: string;
  venueLogo: string | null;
  onBack: () => void;
  onHome: () => void;
}

const Redeem = ({ venueId, venuePrefix, venueLogo, onBack, onHome }: Props) => {
  const [code, setCode] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [background, setBackground] = useState<string>(WHITE);
  const [prize, setPrize] = useState<Prize | undefined>(undefined);

  const redeemCode = useLazyPost<Redemption>(
    `/public/tavern/venues/${venueId}/redemptions`,
    "gameplay"
  );

  const handleRedeem = throttle(async () => {
    const { data, error } = await redeemCode({
      code: code,
    });
    if (error) {
      console.error(error);
      setPrize(undefined);
      if (error.includes("409")) {
        setErrorMessage("This ticket has already been redeemed!");
        setBackground(ORANGE);
      } else {
        setErrorMessage("This ticket is invalid, check the code!");
        setBackground(PINK);
      }
      return;
    }

    setPrize(data?.campaignPrize);
    setErrorMessage(null);
    setBackground(GREEN);
  }, 500);

  return (
    <Page venueLogo={venueLogo} onBack={onBack} onHome={onHome}>
      <Display>Redeem a code</Display>
      <Paragraph>Enter the ticket code</Paragraph>
      <CodeInput
        prefix=""
        background={background}
        onChange={({ target }) => {
          setCode(target.value);
          setBackground(WHITE);
          setErrorMessage(null);
        }}
        onKeyDown={({ key }) => {
          if (key === "Enter") {
            handleRedeem();
          }
        }}
      />
      <Paragraph>Validate this code</Paragraph>
      <Button onClick={handleRedeem}>Redeem</Button>
      <Paragraph>
        {errorMessage}
        {prize && (
          <>
            This ticket is good!
            <br />
            {`The prize is: ${prize.name}`}
            <br />
            <img src={prize.image} width="128px" alt="prize icon" />
          </>
        )}
      </Paragraph>
    </Page>
  );
};

export default Redeem;
