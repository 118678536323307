import styled from "@emotion/styled";
import React, { ChangeEvent, KeyboardEvent } from "react";

import Input from "./Input";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  & > input {
    letter-spacing: 24px;
  }
`;

const Prefix = styled.div`
  display: none;
  font-family: Fira Mono;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 16px;
  text-transform: uppercase;

  @media (min-width: 600px) {
    display: block;
  }
`;

interface Props {
  className?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  background?: string;
  prefix: string;
}

const CodeInput = ({
  className,
  onKeyDown,
  onChange,
  background,
  prefix,
}: Props) => (
  <Wrapper className={className}>
    <Prefix>{prefix}</Prefix>
    <Input
      type="number"
      step="any"
      placeholder="000000"
      onChange={onChange}
      onKeyDown={onKeyDown}
      background={background}
    />
  </Wrapper>
);

export default CodeInput;
