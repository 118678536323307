import styled from "@emotion/styled";

const Grid = styled.div`
  max-height: 100%;
  padding-bottom: 80px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
  row-gap: 2rem;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const SingleGrid = styled(Grid)`
  @media (min-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const EmployeeGrid = styled(Grid)`
  grid-template-columns: 1fr 1fr;

  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export default Grid;
