import React from "react";
import styled from "@emotion/styled";

import Page from "../components/Page/Page";
import Button from "../components/Inputs/Button";
import { SingleGrid } from "../components/Grid/Grid";
import Display from "../components/Text/Display";

const Text = styled(Display)`
  margin-top: -3rem;
  margin-bottom: 3rem;
`;

interface Props {
  employeeName: string;
  venueLogo: string | null;
  onSetAction: (action: Action) => void;
  onBack: () => void;
}

const Start = ({ employeeName, venueLogo, onSetAction, onBack }: Props) => {
  return (
    <Page venueLogo={venueLogo} onBack={onBack}>
      <Text>Hi {employeeName}... let&apos;s go!</Text>
      <SingleGrid>
        <Button key="redeem" onClick={() => onSetAction("redeem")}>
          Redeem Code
        </Button>
      </SingleGrid>
    </Page>
  );
};

export default Start;
