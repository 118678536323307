import React from "react";

import { useGet } from "../Data";
import Loading from "./Loading";
import Page from "../components/Page/Page";
import { EmployeeButton as Button } from "../components/Inputs/Button";
import { EmployeeGrid as Grid, SingleGrid } from "../components/Grid/Grid";

interface Props {
  venueId: string;
  venueLogo: string | null;
  onSetUser: (user: Employee) => void;
}

const Employee = ({ venueId, venueLogo, onSetUser }: Props) => {
  const { data, loading } = useGet<Employee[]>(
    `/public/tavern/venues/${venueId}/employees`,
    "venues"
  );

  if (loading) {
    return <Loading />;
  }

  const users = (data || []).map((user) => (
    <Button key={user.id} onClick={() => onSetUser(user)}>
      {user.name}
    </Button>
  ));

  const DisplayGrid = data?.length === 1 ? SingleGrid : Grid;

  return (
    <Page venueLogo={venueLogo}>
      <DisplayGrid>{users}</DisplayGrid>
    </Page>
  );
};

export default Employee;
